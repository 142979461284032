const region = "us-east-1";
const origin = window.location.origin;

const staging = {
  appSettingsData: {
    // generate a file format limit of video file
    federatedEngageAIProvider: "wl-stg-translate-client-site-idp",
  },

  amplify_config: {
    Auth: {
      mandatorySignIn: true,
      region: region,
      userPoolId: "us-east-1_jWl0IXc78",
      userPoolWebClientId: "5b7nkp53p3k92em6i94f4kclot",
    },
    oauth: {
      domain: "wl-stg-engage-translate-client-site.auth.us-east-1.amazoncognito.com",
      scope: [],
      redirectSignIn: `${origin}/view`,
      redirectSignOut: `${origin}/`,
      responseType: "code",
      clientId: "5b7nkp53p3k92em6i94f4kclot",
    },
    API: {
      endpoints: [
        {
          name: "engage-translate",
          // endpoint: "https://api.dev.wl-dms.com",
          endpoint: "https://api.stg.translate.wl-engage.com",
          region: region,
        },
      ],
    },
  },
};
// type StagingType = typeof staging

export type StagingType = typeof staging;
export default staging;
