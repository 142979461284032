import { Form, Input, Upload, UploadProps, notification, message, Select, Checkbox, Modal, Spin, Button, UploadFile } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import TextArea from "antd/es/input/TextArea";
import { useNavigate } from "react-router";
import { getFileTypeIconProps } from "@fluentui/react-file-type-icons";
import translationFailedImg from "../../assets/img/translation-failed.jpeg";
import translationProcessingImg from "../../assets/img/translation-processing.jpeg";
import translationTickImg from "../../assets/img/tick.jpeg";
import { FontIcon, Icon } from "@fluentui/react";
import { getExtension } from "../../utils";
import { clearUploadStatusData, documentUpload, fetchQuickTranslateLanguageList, fetchTargetLanguageList, getDocumentInfo, uploadedStatus } from "../../redux/EngageTranslation/EngageTranslationAction";
import { useAppDispatch } from "../../redux/useAppDispatch";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/Store";
import "./UploadPage.scss";

function UploadPage({ setFileList, fileList, retryAttempts, setRetryAttempts }: any) {
  const [isQCSelected, setIsQCSelected] = useState(false);
  const [openQCModal, setOpenQCModal] = useState(false);
  const [openLoadingModal, setOpenLoadingModal] = useState(false);
  const [openFailModal, setOpenFailModal] = useState(false);
  const [formData, setFormData] = useState<any>();
  const { Dragger } = Upload;
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const LanguageFormState = useSelector((state: RootState) => state.engageTranslationSlice.engageTranslateMainState.quickTextTranslateDataState);
  const UploadPageState = useSelector((state: RootState) => state.engageTranslationSlice.engageTranslateMainState.uploadPage);
  const acceptFormat = ["application/pdf", "application/msword", "application/vnd.openxmlformats-officedocument.wordprocessingml.document"];

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
    },
    wrapperCol: {
      xs: { span: 24 },
    },
  };

  const tagsList = [
    { label: "Urgent", value: "Urgent", id: 1 },
    { label: "Confidential", value: "Confidential", id: 2 },
    { label: "Non-Urgent", value: "Non-Urgent", id: 3 },
  ];

  // useEffect(() => {
  //   if (UploadPageState.data) {
  //     clearUploadForm();
  //   }
  // }, [UploadPageState.data]);

  const clearUploadForm = () => {
    form.resetFields();
    setIsQCSelected(false);
    setFileList([]);
  };

  const uploadProps: UploadProps = {
    name: "file",
    multiple: false,
    showUploadList: false,

    beforeUpload: (file) => {
      const isAcceptable = acceptFormat.includes(file.type);

      if (!isAcceptable) {
        return false;
      }
      setFileList(() => [file]);

      return false;
    },
  };

  const arrangeLanguagesAlphabetically = (languagesArray: Array<{ label: string; value: number; id: number }>) => {
    return [...languagesArray].sort((a: any, b: any) => a.label.localeCompare(b.label, "en", { sensitivity: "base" }));
  };

  const handleSubmission = (values: any) => {
    let refinedValues: any = {};
    const userData = JSON.parse(JSON.parse(JSON.stringify(localStorage.getItem("userData"))));
    dispatch(clearUploadStatusData());

    values.forEach((item: any) => {
      refinedValues = {
        file: fileList[0],
        sourceLanguageId: item.sourceLanguage,
        targetLanguageIds: item.outputLanguage,
        uploadedBy: userData.idToken.payload.email,
        tags: item.tags.length > 0 ? item.tags.join(",") : "",
        additionalNotes: item.notes,
        isQcRequired: isQCSelected,
      };
    });

    dispatch(documentUpload(refinedValues));
    console.log("VALS", refinedValues);
  };

  useEffect(() => {
    dispatch(fetchQuickTranslateLanguageList());
  }, []);

  useEffect(() => {
    if (!openFailModal && !openLoadingModal) {
      setRetryAttempts(3);
    }
  }, [openFailModal, openLoadingModal]);

  // useEffect(() => {
  //   let timerVar: any;

  //   if (isQCSelected === false) {
  //     if (UploadPageState?.uploadStatus?.data?.status === "Completed" && UploadPageState.data) {
  //       dispatch(clearUploadStatusData());
  //       navigate(`/view-document/${UploadPageState?.data?.id}`);
  //       setOpenLoadingModal(false);
  //       clearUploadForm();
  //       console.log("completeddd");
  //       return () => {
  //         console.log("return");
  //         clearInterval(timerVar);
  //       };
  //     } else if (UploadPageState?.uploadStatus?.data?.status === "Translation Failed") {
  //       dispatch(clearUploadStatusData());
  //       setOpenLoadingModal(false);
  //       setOpenFailModal(true);
  //       console.log("fail");
  //     } else if (!openFailModal && UploadPageState.data.id !== 0 && openLoadingModal) {
  //       timerVar = setInterval(() => dispatch(uploadedStatus(UploadPageState.data.id)), 10000);
  //       console.log(UploadPageState.uploadStatus.data);
  //       console.log("timer");
  //     }
  //   }
  // }, [formData, UploadPageState.data, UploadPageState.uploadStatus.data, openFailModal, openLoadingModal]);

  useEffect(() => {
    let timerVar: any;

    if (isQCSelected === false) {
      // Completed
      if (UploadPageState?.uploadStatus?.data?.status === "Completed" && UploadPageState.data) {
        dispatch(clearUploadStatusData());
        setOpenLoadingModal(false);
        clearUploadForm();

        return () => {
          navigate(`/view-document/${UploadPageState?.data?.id}?target=${UploadPageState?.uploadStatus?.data?.translatedDocs[0].id}&language=${UploadPageState?.uploadStatus?.data?.translatedDocs[0].targetLanguageName.replace(/\s/g, "")}`);
        };
      }

      // Failed
      if (UploadPageState?.uploadStatus?.data?.status === "Translation Failed" && UploadPageState.data) {
        dispatch(clearUploadStatusData());
        setOpenLoadingModal(false);
        setOpenFailModal(true);
      }

      // Loading (Timer)
      if (UploadPageState.data.id !== 0 && openLoadingModal && !openFailModal && UploadPageState?.uploadStatus?.data?.status !== "Completed") {
        timerVar = setInterval(() => dispatch(uploadedStatus(UploadPageState.data.id)), 10000);
      }

      return () => {
        clearInterval(timerVar);
      };
    }
  }, [UploadPageState.data.id, openLoadingModal, isQCSelected, UploadPageState?.uploadStatus?.data?.status]);

  // if (LanguageFormState.loading) {
  //   return (
  //     <div
  //       className="app"
  //       style={{ textAlign: "center", display: "flex", alignItems: "center", justifyContent: "center" }}>
  //       <Spin size="large" />
  //     </div>
  //   );
  // }

  return (
    <div className="outer-up-container">
      <div
        className="up-container"
        id="fileUploadForm">
        <h4 className="up-title">Upload Document</h4>
        <div className="d-flex gap-5">
          <Form
            form={form}
            className="up-form"
            onFinish={(values) => {
              handleSubmission([values]);
              setFormData([values]);
              if (isQCSelected) {
                setOpenQCModal(true);
              }
              if (!isQCSelected) {
                setOpenLoadingModal(true);
              }
            }}
            scrollToFirstError
            {...formItemLayout}
            colon={false}>
            <div className="up-left-fields">
              <Spin spinning={LanguageFormState.loading}>
                <Form.Item
                  className="w-100"
                  name={"sourceLanguage"}
                  required
                  rules={[{ required: true, message: "Please provide an source language." }]}
                  label="Source Language">
                  <Select
                    onChange={(e) => {
                      dispatch(fetchTargetLanguageList(e));
                    }}
                    options={arrangeLanguagesAlphabetically(LanguageFormState.sourceLanguageList)}
                  />
                </Form.Item>
                <Form.Item
                  className="w-100"
                  name={"outputLanguage"}
                  required
                  rules={[{ required: true, message: "Please provide an output language." }]}
                  label="Output Language(s)">
                  <Select
                    mode="tags"
                    style={{
                      width: "100%",
                    }}
                    options={arrangeLanguagesAlphabetically(LanguageFormState.targetLanguageList)}
                  />
                </Form.Item>
                <Form.Item
                  className="w-100"
                  name={"tags"}
                  initialValue={[]}
                  label="Tags">
                  <Select
                    mode="tags"
                    style={{
                      width: "100%",
                    }}
                    options={tagsList}
                  />
                </Form.Item>
                <Form.Item
                  className="w-100"
                  name={"notes"}
                  initialValue={""}
                  label="Notes">
                  <TextArea
                    defaultValue={""}
                    placeholder="Additional information..."
                    showCount
                    maxLength={500}
                    autoSize={{
                      minRows: 4,
                      maxRows: 4,
                    }}
                  />
                </Form.Item>
                <Form.Item
                  initialValue={isQCSelected}
                  name={"qcReview"}>
                  <Checkbox
                    checked={isQCSelected}
                    onChange={(e) => setIsQCSelected(e.target.checked)}>
                    QC Review
                  </Checkbox>
                </Form.Item>
              </Spin>
            </div>
            <Form.Item
              className="up-dragger-container"
              name="file"
              valuePropName="file"
              getValueFromEvent={(e) => e?.fileList}
              rules={[
                {
                  validator: (rule, value, callback) => {
                    // Custom validation logic
                    if (fileList[0]?.size / 1024 / 1024 > 2000) {
                      callback("File size should be less than 2 GB");
                    } else if (!acceptFormat.includes(fileList[0]?.type)) {
                      callback("Please upload a PDF or DOCX document");
                    } else if (rule.required) {
                      callback("Please upload a document");
                    } else {
                      callback();
                    }
                  },
                },
              ]}>
              <div className="up-dragger">
                <Dragger
                  height={500}
                  {...uploadProps}
                  accept={".pdf, .doc, .docx"}>
                  {fileList.length > 0 ? (
                    <div className="d-flex flex-column align-items-center">
                      <div>
                        <Icon
                          {...getFileTypeIconProps({
                            extension: getExtension(fileList[0]?.type),
                            size: 96,
                            imageFileType: "svg",
                          })}
                        />
                      </div>
                      {fileList[0]?.name}
                    </div>
                  ) : (
                    <div>
                      <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                      </p>
                      <p className="ant-upload-text">Click or drag a file to this area to upload</p>
                      <p className="ant-upload-hint">Support for a single upload</p>
                    </div>
                  )}
                </Dragger>
              </div>
            </Form.Item>
          </Form>
        </div>

        <div
          style={{ justifyContent: "flex-end", paddingBottom: "10px" }}
          className="d-flex gap-4 w-100">
          <div className="d-flex gap-4">
            <button
              disabled={openLoadingModal || openFailModal}
              type="submit"
              onClick={() => {
                form.submit();
              }}
              className="button">
              Upload
            </button>
            <button
              type="button"
              onClick={clearUploadForm}
              className="button warning">
              Clear
            </button>
          </div>
        </div>
      </div>
      <Modal
        centered
        width={700}
        zIndex={10000}
        onOk={() => {
          setOpenQCModal(false);
        }}
        afterClose={() => {
          clearUploadForm();
          setOpenQCModal(false);
        }}
        closeIcon={false}
        closable={true}
        onCancel={() => {
          clearUploadForm();
          setOpenQCModal(false);
        }}
        footer={[
          <button
            onClick={() => {
              clearUploadForm();
              setOpenQCModal(false);
              setIsQCSelected(false);
            }}
            style={{ marginLeft: "auto" }}
            className="button action">
            Ok
          </button>,
        ]}
        open={openQCModal}>
        <div className="openqcmodal-container">
          <img
            className="tick-img"
            src={translationTickImg}
            alt="tick"
            loading="eager"
          />
          <h4 style={{ marginBottom: "14px", marginTop: "10px" }}>Document Submitted for Review</h4>
          <div style={{}}>Your document is being translated and will be reviewed shortly by one of our team members. You will receive an email once completed.</div>
        </div>
      </Modal>
      {/* Processing Modal */}
      <Modal
        centered
        width={700}
        zIndex={10000}
        closeIcon={false}
        afterClose={() => {
          setOpenLoadingModal(false);
        }}
        footer={[]}
        open={openLoadingModal}>
        <div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <img
              style={{ width: "140px", marginRight: "40px" }}
              src={translationProcessingImg}
              alt="img"
              loading="eager"
            />
          </div>
          <h4 style={{ textAlign: "center", marginBottom: "10px" }}>Processing...</h4>
          <div style={{ textAlign: "center" }}>You will have time to make a coffee while your document is being translated &#128578;</div>
        </div>
      </Modal>
      <Modal
        centered
        width={700}
        zIndex={10000}
        closeIcon={true}
        onCancel={() => {
          setOpenFailModal(false);
          clearUploadForm();
        }}
        afterClose={() => {
          setOpenFailModal(false);
        }}
        footer={[
          <div style={{ display: "flex", justifyContent: "flex-end", gap: "10px" }}>
            <button
              disabled={retryAttempts <= 0}
              onClick={() => {
                setOpenFailModal(false);
                setOpenLoadingModal(true);
                setRetryAttempts(retryAttempts - 1);
                handleSubmission(formData);
              }}
              className="button action retry-btn ">
              <FontIcon iconName="Refresh" />
              Retry
            </button>

            <button
              onClick={() => {}}
              className="button action submit-ticket-btn">
              <FontIcon
                style={{ marginTop: "1px" }}
                iconName="Ticket"
              />
              Submit a ticket
            </button>

            {/* <button
              onClick={() => {
                setOpenFailModal(false);
              }}
              className="button action close-btn-fail">
              <FontIcon
                style={{ fontSize: "10px", marginTop: "1px" }}
                iconName="ChromeClose"
              />
              Close
            </button> */}
          </div>,
        ]}
        open={openFailModal}>
        <div>
          <div style={{ display: "flex", justifyContent: "center", marginBottom: "5px" }}>
            <img
              style={{ width: "140px", marginLeft: "10px" }}
              src={translationFailedImg}
              alt="img"
              loading="eager"
            />
          </div>
          <h4 style={{ textAlign: "center", marginBottom: "10px" }}>Translation Failed</h4>
          <div style={{ textAlign: "center", marginBottom: "40px" }}>Please check the input document and try again or submit a ticket.</div>
        </div>
      </Modal>
    </div>
  );
}

export default UploadPage;
